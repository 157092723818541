<template>
  <div class="b-card-movements">
    <div :class="['b-card-movements--title', color]">
      <v-icon>{{ icon }}</v-icon>
      <span v-if="title">
        {{ title }}
      </span>
    </div>
    <div class="b-card-movements__content">
      <span class="b-card-movements__content--description" v-if="description">
        {{ description }}
      </span>

      <v-btn
        text
        color="primary"
        class="b-card-movements__content--link"
        @click="execute"
      >
        Iniciar movimentação
        <v-icon right>fa-solid fa-right-long</v-icon>
      </v-btn>
    </div>

    <DialogDependent
      v-if="dialog"
      :employeeId="employeeId"
      :dialog="dialog"
      :title="dialogTitle"
      :edit="dialogEdit"
      :selectItem="selectItem"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import { movementMixin } from '@/mixins/movements';

export default {
  mixins: [movementMixin],

  components: {
    DialogDependent: () => import('../dialogs/dialog-dependents')
  },

  props: {
    component: {
      type: String,
      require: true
    },
    title: {
      type: String,
      require: true
    },
    description: {
      type: String,
      require: true
    },
    icon: {
      type: String,
      require: true
    },
    color: {
      type: String,
      require: true
    },
    employeeId: {
      type: String,
      require: true
    },
    employeeOrDependentId: {
      type: String,
      require: true
    },
    customerId: {
      type: String,
      require: true
    }
  },

  data: () => ({
    dialog: false,
    dialogEdit: false,
    dialogTitle: '',
    selectItem: {}
  }),

  computed: {
    header() {
      return this.$store.getters['movements/getMovementHeader'];
    }
  },

  methods: {
    execute() {
      const {
        customerId,
        employeeId,
        employeeOrDependentId,
        title,
        component
      } = this;

      if (component === 'PageEmployee') {
        if (this.header && this.header.kinship === 'Titular') {
          this.$router.push({
            name: 'movimentacoes-new',
            params: {
              customerId,
              employeeId: employeeOrDependentId,
              returnUrl: 'edit'
            }
          });
        } else {
          this.openDialogDependent();
        }
      } else {
        this.$router.push({
          name: 'movimentacoes-card',
          params: {
            customerId,
            employeeId,
            employeeOrDependentId,
            title,
            component
          }
        });
      }
    },

    openDialogDependent() {
      this.dialog = true;
      this.dialogEdit = true;
      this.dialogTitle = 'Visualizar dependente';
      this.selectItem = Object.assign({}, this.header);
    },

    closeModal(search) {
      this.dialog = false;
      this.dialogEdit = false;
      this.dialogTitle = '';
      this.selectItem = {};

      if (search) {
        this.getHeader();
      }
    }
  }
};
</script>
