<template>
  <BaseSearchPage class="mt-0">
    <template v-slot:form>
      <v-row dense>
        <v-col cols="6" xl="3" lg="3" md="3" sm="6">
          <div class="b-form-information">
            <label class="b-form-information--title">Nome</label>
            <span class="b-form-information--description">
              {{ model.name }}
            </span>
          </div>
        </v-col>
        <v-col cols="6" xl="3" lg="3" md="3" sm="6">
          <div class="b-form-information">
            <label class="b-form-information--title">Grau de parentesco</label>
            <span class="b-form-information--description primary--text">
              {{ model.kinship }}
            </span>
          </div>
        </v-col>
        <v-col cols="6" xl="3" lg="3" md="3" sm="6">
          <div class="b-form-information">
            <label class="b-form-information--title">CPF</label>
            <span class="b-form-information--description">
              {{ formatCpf(model.document) }}
            </span>
          </div>
        </v-col>
        <v-col cols="6" xl="3" lg="3" md="3" sm="6">
          <div class="b-form-information">
            <label class="b-form-information--title">Status</label>
            <span
              class="b-form-information--description"
              :class="getColor(model.situation)"
            >
              {{ model.situation }}
            </span>
          </div>
        </v-col>
      </v-row>
    </template>
  </BaseSearchPage>
</template>

<script>
import { getColor } from '@/helpers/utils';
import { formatCpf } from '@/helpers/formatting';
import { movementMixin } from '@/mixins/movements';

export default {
  mixins: [movementMixin],

  props: {
    employeeId: {
      type: String,
      require: true
    },
    employeeOrDependentId: {
      type: String,
      require: true
    }
  },

  computed: {
    model() {
      return this.$store.getters['movements/getMovementHeader'];
    }
  },

  created() {
    this.getHeader();
  },

  methods: {
    formatCpf,
    getColor
  }
};
</script>

<style lang="scss">
.b-form-information {
  display: flex;
  flex-direction: column;

  &--title {
    font-weight: 300;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    width: 100%;
  }

  &--description {
    font-size: 12px;
    font-weight: 500;
    word-break: break-word;
  }
}
</style>
