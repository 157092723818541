import { MovementService } from '@/services/api/movements';

export const movementMixin = {
  methods: {
    async getHeader() {
      try {
        const movementService = new MovementService();
        const { status, data } = await movementService.getMovementHeader({
          employeeId: this.employeeId,
          employeeOrDependentId: this.employeeOrDependentId
        });

        if (status === 200) {
          this.$store.commit('movements/setMovementHeader', data);
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  }
};
