<template>
  <v-container>
    <BaseTitlePage class="mb-0" />

    <MovementsDetail
      :employeeId="$route.params.employeeId"
      :employeeOrDependentId="$route.params.employeeOrDependentId"
    />

    <v-row>
      <v-col cols="12">
        <h5 class="mb-4">Movimentações disponíveis</h5>
        <p>
          Todas as movimentações de funcionários e dependentes são feitas
          através dessa tela utilizando o menus rápidos abaixo,
          <strong class="primary--text">
            é importante deixar claro que alguns movimentos geram custo para a
            empresa na próxima fatura mensal,
          </strong>
          por isso separamos de forma fácil e independente cada um dos itens que
          serão gerenciados.
        </p>
      </v-col>
    </v-row>

    <v-row class="pb-5">
      <template v-for="(card, index) in cards">
        <v-col
          cols="12"
          xl="3"
          lg="4"
          md="4"
          sm="6"
          :key="index"
          v-if="card.visible"
        >
          <MovementsCards
            :component="card.component"
            :title="card.title"
            :description="card.description"
            :icon="card.icon"
            :color="index % 2 == 0 ? 'primary' : 'secondary'"
            :employeeId="$route.params.employeeId"
            :employeeOrDependentId="$route.params.employeeOrDependentId"
            :customerId="$route.params.customerId"
          />
        </v-col>
      </template>
    </v-row>

    <v-row>
      <v-col cols="12" class="text-right">
        <BaseButton
          id="btn-back"
          name="btn-back"
          outlined
          color="primary"
          title="Voltar"
          @click="back"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MovementsCards from '@/components/pages/movements/cards/movements-card';
import MovementsDetail from '@/components/pages/movements/details/movements-detail';

export default {
  components: {
    MovementsCards,
    MovementsDetail
  },

  computed: {
    cards() {
      return this.$store.getters['movements/getCards'];
    }
  },

  methods: {
    back() {
      const { customerId } = this.$route.params;
      this.$router.push({ name: 'movimentacoes-list', params: { customerId } });
    }
  }
};
</script>
